import AppLogo from '@/components/lib/AppLayout/components/AppLogo'
import IntlMessages from '@/helpers/IntlMessages'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <footer className="footer-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="single-footer-widget">
              <h3>
                <IntlMessages id="footer.officelocation" />
              </h3>
              <span>
                <i className="icofont-calendar"></i>
                <IntlMessages id="footer.date" />
              </span>

              <p className="location">
                <i className="icofont-google-map"></i>
                <IntlMessages id="contactus.address" />
              </p>

              <Link to="/contact" className="contact-authority">
                <i className="icofont-phone"></i>
                <IntlMessages id="contactus.phone1" />
              </Link>
            </div>
          </div>

          <div className="col-lg-6 col-md-6">
            <div className="single-footer-widget">
              <h3>
                <IntlMessages id="footer.socialconnection" />
              </h3>
              <p>
                <IntlMessages id="footer.don'tmisslink" />
              </p>

              <ul className="social-links">
                <li>
                  <Link
                    to="https://www.youtube.com/channel/UCBA-3eWMYhPmUtsINkDVLPQ"
                    className="youTube"
                    target="_blank"
                  >
                    <i className="icofont-youtube-play"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.meetup.com/krazzy-adventures/"
                    className="meetup"
                    target="_blank"
                  >
                    <i className="icofont-meetup"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.linkedin.com/company/krazzyadventures"
                    className="linkedin"
                    target="_blank"
                  >
                    <i className="icofont-linkedin"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.instagram.com/krazzyadventures/"
                    className="instagram"
                    target="_blank"
                  >
                    <i className="icofont-instagram"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="copyright-area">
              <div className="logo">
                <AppLogo />
              </div>
              <ul>
                <li>
                  <Link to="/contact">
                    <IntlMessages id="footer.contact" />
                  </Link>
                </li>
                <li>
                  <Link to="/terms-services">
                    <IntlMessages id="footer.terms&conditions" />
                  </Link>
                </li>
                <li>
                  <Link to="/private-policy">
                    <IntlMessages id="footer.privatepolicy" />
                  </Link>
                </li>
              </ul>
              <p>
                <IntlMessages id="footer.copyright" />
                <i className="icofont-copyright"></i>
                <IntlMessages id="footer.krazzycopyright" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
