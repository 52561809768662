import { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useAuthUser } from '@/hooks/AuthHooks'
import AppLogoWhite from '@/components/lib/AppLayout/components/AppLogoWhite'
import IntlMessages from '@/helpers/IntlMessages'
import UserInfo from '@/components/lib/AppLayout/components/UserInfo'
import { useAdmin } from '@/hooks/KrazzyApi/IsAdmin'

const Navigation = () => {
  const [collapsed, setCollapsed] = useState(true)
  const { isAuthenticated } = useAuthUser()
  const admin = useAdmin()
  const toggleNavbar = () => {
    setCollapsed(!collapsed)
  }

  useEffect(() => {
    const elementId = document.getElementById('navbar')

    const handleScroll = () => {
      if (window.scrollY > 170) {
        if (
          admin === 'admin' ||
          admin === 'organizer' ||
          admin === 'coorganizer'
        ) {
          elementId?.classList.add('is-stickyAuth')
        } else {
          elementId?.classList.add('is-sticky')
        }
      } else {
        if (
          admin === 'admin' ||
          admin === 'organizer' ||
          admin === 'coorganizer'
        ) {
          elementId?.classList.remove('is-stickyAuth')
        } else {
          elementId?.classList.remove('is-sticky')
        }
      }
    }

    document.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const classOne = collapsed
    ? 'collapse navbar-collapse'
    : 'collapse navbar-collapse show'
  const classTwo = collapsed
    ? 'navbar-toggler navbar-toggler-right collapsed'
    : 'navbar-toggler navbar-toggler-right'

  return (
    <header id="header" className="header-area">
      <div id="navbar" className="elkevent-nav">
        <nav className="navbar navbar-expand-md navbar-light">
          <div className="container">
            <AppLogoWhite />

            <button
              onClick={toggleNavbar}
              className={classTwo}
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className={classOne} id="navbarSupportedContent">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink to="/" className="nav-link">
                    <IntlMessages id="nav.home" />
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/about-us" className="nav-link">
                    <IntlMessages id="nav.aboutus" />
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink to="/contact" className="nav-link">
                    <IntlMessages id="nav.contact" />
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink to="/all-events" className="nav-link">
                    <IntlMessages id="nav.events" />
                  </NavLink>
                </li>
              </ul>

              {isAuthenticated ? (
                !(
                  admin === 'admin' ||
                  admin === 'organizer' ||
                  admin === 'coorganizer'
                ) ? (
                  <UserInfo />
                ) : (
                  ''
                )
              ) : (
                <div className="others-option">
                  <ul>
                    <li>
                      <NavLink to="/signin" className="btn btn-primary">
                        <IntlMessages id="common.login" />
                      </NavLink>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Navigation
